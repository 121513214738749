import React from 'react'
import { useDrop } from 'react-dnd'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { checkHourOpacity } from '../../../help/checkHourOpacity'
import { CheckJustHours } from '../../../help/CheckJustHours'
import { useSocket } from '../../../controllers/SocketProvider'
import { CalendarTicket } from './CalendarTicket'

const MainDatesHour = ({ hour, ticket, currentTime, master }) => {
  const { addTicketToGrid } = useSocket()
  const { searchTickets } = useSelector((state) => state.headerSort)
  const { user } = useSelector((state) => state.auth)
 

  const checkCanDropHour = () => {
    const isWork = hour.isWork
    const isCheckHours = CheckJustHours(hour?.time, currentTime)
    const masterFrilance = master.role === 'freelance_master'
    const isIgnoreTimeFrilance = master.IgnoreTimeRange === 1

    if (!isCheckHours) return false
    if (isCheckHours && !isWork) return false
    if (
      (hour.ticket && !masterFrilance) ||
      (!isIgnoreTimeFrilance && masterFrilance && hour.ticket.length > 0)
    ) {
      return false
    }

    return true
  }

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'ticket',
    drop: (item) => {
      addTicketToGrid({
        ticket_id: item.id,
        master_id: master.master_id,
        timeBegin: hour.time,
        gridCount: 4,
      })
    },
    canDrop: checkCanDropHour,
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
  })

  const backgroundColor = () => {
    const variant = ['rgb(210, 210, 210)', 'yellow', 'white'];
    const isActive = canDrop && isOver
    const checkBackgroundLine =
      !hour.isWork || !CheckJustHours(hour?.time, currentTime)

    if (checkBackgroundLine) {
      return variant[0]
    } else if (!checkBackgroundLine && isActive) {
      return variant[1]
    } else if (!checkBackgroundLine && !isActive) {
      return variant[2]
    }

    return variant[2]
  }

  return (
    <div
      ref={drop}
      className="dates-block-dates-wrap-item-date-scroll-time-wrap"
    >
      <h3
        className="dates-block-dates-wrap-item-date-scroll-time-wrap-hour"
        style={{
          opacity: checkHourOpacity(ticket?.manager_id, user?.id) ? 1 : 0.5,
        }}
      >
        {moment(hour?.time).format('HH.mm')}
      </h3>

      <div
        className={
          Array.isArray(hour?.ticket) && hour.ticket.length > 0
            ? 'dates-block-dates-wrap-item-date-scroll-time-wrap-block'
            : 'dates-block-dates-wrap-item-date-scroll-time-wrap-line'
        }
        style={{ backgroundColor: backgroundColor() }}
      >
        {Array.isArray(hour.ticket) &&
          hour.ticket.length > 0 &&
          user &&
          hour &&
          hour.ticket.map((item) => (
            <CalendarTicket
              key={item.id}
              ticket={item}
              hour={hour}
              user={user}
              searchTickets={searchTickets}
            />
          ))}

        {!Array.isArray(hour.ticket) && ticket && user && hour && (
          <CalendarTicket
            key={ticket.id}
            ticket={ticket}
            hour={hour}
            user={user}
            searchTickets={searchTickets}
          />
        )}
      </div>
    </div>
  )
}

export default MainDatesHour
