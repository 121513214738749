import React, { useEffect, useState } from 'react'
import Nav from '../Nav'
import './style.scss'
import DropDown from '../DropDown'
import { ArrowToBottom } from '../../../assets/icons/Arrows'
import DropDownCheckBox from '../DropDownCheckBox'
import Input from '../../Common/Input'
import SearchInput from '../../Common/SearchInput'
import Search from '../../../assets/icons/Search'
import LogOut from '../../../assets/icons/LogOut'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../../redux/auth'
import { useGet } from '../../../api/request'
import {
  setCurrentCity,
  setCurrentTypeOfWork,
  setCurrentWorks,
  setIsLoadingSearchMode,
  setIsSearchMode,
  setSearchTickets,
  setSearchValue,
} from '../../../redux/headerSort'
import { useAlert } from '../../../controllers/AlertNotification'
import { setCurrentMaster } from '../../../redux/application'
import TokenStorage from '../../../api/TokenStorage'
import { useLocation } from 'react-router-dom'

const Header = () => {
  const dispatch = useDispatch()
  const getU = useGet()
  const alertU = useAlert()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const ticketParam = searchParams.get('ticket')

  useEffect(() => {
    if (ticketParam) {
      setTimeout(() => {
        dispatch(setSearchValue(`id:${ticketParam}`))
        handleOnSearchMode(`id:${ticketParam}`)
      }, 1000)
    }
  }, [ticketParam])

  const { currentTypeOfWork } = useSelector((state) => state.headerSort)

  const { currentCity, currentWorks, searchValue } = useSelector(
    (state) => state.headerSort
  )

  // console.log('searchValue')
  // console.log(searchValue)

  // const [currentTypeOfWork, setCurrentTypeOfWork] = useState([])

  const [isLoadingCity, setIsLoadingCity] = useState(false)
  const [isLoadingCategory, setIsLoadingCategory] = useState(false)

  function removeQueryParam(paramKey) {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.delete(paramKey)
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`
    window.history.replaceState({}, '', newUrl)
  }

  const onChangeTypeOfWork = async (currentItem) => {
    let find = currentTypeOfWork.find((item) => item?.id === currentItem?.id)

    if (find) {
      // Убираем значение из массива, если оно уже есть
      dispatch(
        setCurrentTypeOfWork(
          currentTypeOfWork.filter((item) => item?.id !== currentItem?.id)
        )
      )
      // dispatch(setCurrentWorks(currentWorks.filter(item => item?.id !== currentItem?.id)))
    } else {
      // Добавляем значение в массив, если его нет
      dispatch(setCurrentTypeOfWork([...currentTypeOfWork, currentItem]))
      // dispatch(setCurrentWorks([...currentWorks, currentItem]))
    }
  }

  const [listOfCities, setListOfCities] = useState([])
  const [listOfMasters, setListOfMasters] = useState([])

  const onLogOut = () => {
    dispatch(logout())
    TokenStorage.logOut()
  }

  const getSortData = async () => {
    await setIsLoadingCity(true)
    await setIsLoadingCategory(true)
    await getU('/region')
      .then((response) => {
        if (response.status === 'success') {
          // console.log(response)
          setListOfCities(response?.data?.region)
        }
      })
      .catch((e) => {
        console.log(e?.response?.data)
      })
      .finally(() => {
        setIsLoadingCity(false)
      })

    await getU('ticket_category')
      .then((response) => {
        if (response.status === 'success') {
          // console.log(response)
          setListOfMasters(response?.data?.category)
        }
      })
      .catch((e) => {
        console.log(e?.response?.data)
      })
      .finally(() => {
        setIsLoadingCategory(false)
      })
  }

  const onClearSearch = () => {
    dispatch(setSearchValue(''))
    dispatch(setSearchTickets({}))
    dispatch(setIsSearchMode(false))
    removeQueryParam('ticket')
  }

  const handleOnSearchMode = (onQuerySearch) => {
    if (searchValue || onQuerySearch) {
      // console.log('searchValue')
      // console.log(searchValue)
      dispatch(setIsSearchMode(true))
      dispatch(setIsLoadingSearchMode(true))
      getU(`ticket/search/${searchValue || onQuerySearch}`)
        .then((response) => {
          if (response.status === 'success') {
            console.log(response.data)
            dispatch(setSearchTickets(response?.data?.ticket))
            dispatch(setCurrentCity(response?.data?.region))
            dispatch(setCurrentWorks([response?.data?.ticket?.category]))
            dispatch(setCurrentTypeOfWork([response?.data?.ticket?.category]))
            dispatch(setCurrentMaster(response?.data?.master_id))
          }
        })
        .catch((e) => {
          console.log(e)
          alertU({
            status: 'error',
            title: 'Поиск',
            message: e?.response?.data?.message,
          })
          dispatch(setIsSearchMode(false))
        })
        .finally(() => {
          dispatch(setIsLoadingSearchMode(false))
        })
    } else {
      console.log('not found')
    }
  }

  useEffect(() => {
    getSortData()
  }, [])

  useEffect(() => {
    if (!searchValue) {
      dispatch(setIsSearchMode(false))
      dispatch(setSearchTickets({}))
    }
  }, [searchValue])

  return (
    <div className="header">
      <div className="header-bckg" />

      <div className="header_left-bar">
        <DropDown
          isLoadingCity={isLoadingCity}
          currentItem={currentCity}
          setCurrentItem={(e) => dispatch(setCurrentCity(e))}
          itemsForChoose={listOfCities}
          backIcon={<ArrowToBottom />}
        />

        <DropDownCheckBox
          isLoading={isLoadingCategory}
          currentItem={currentTypeOfWork}
          setCurrentItem={(item) => onChangeTypeOfWork(item)}
          onCleaer={() => dispatch(setCurrentTypeOfWork([]))}
          itemsForChoose={listOfMasters}
          backIcon={<ArrowToBottom />}
        />

        <SearchInput
          onSearch={handleOnSearchMode}
          value={searchValue}
          onChange={(e) => dispatch(setSearchValue(e.target.value))}
          onClear={onClearSearch}
          placeholder={'Поиск'}
          leftIcon={<Search />}
        />
      </div>
      <div className="header_right-bar">
        <Nav />

        <div className="header_right-bar-logout" onClick={onLogOut}>
          <LogOut />
        </div>
      </div>
    </div>
  )
}

export default Header
