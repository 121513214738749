import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import MainHeader from '../../components/MainTest/MainHeader'
import MainDates from '../../components/MainTest/MainDates'
import { useSocket } from '../../controllers/SocketProvider'
import './styles.scss'

const Main = () => {
  const { addTicketToGrid } = useSocket()

  const { currentMaster } = useSelector((state) => state.application)
  const { tickets, grid, isLoadingGrid, gridCount, isGridUpdate } = useSelector(
    (state) => state.dragContent
  )
  const { searchTickets } = useSelector((state) => state.headerSort)

  const handleCardDrop = (cardId, hourId) => {
    addTicketToGrid({
      ticket_id: cardId,
      masterd_id: currentMaster.master_id,
      timeBegin: hourId,
      gridCount: gridCount,
    })
  }

  return (
    <div className="test-main-wrap">
      <MainHeader tickets={tickets} searchTickets={searchTickets} />
      <MainDates
        searchTickets={searchTickets}
        isLoadingGrid={isLoadingGrid}
        grid={grid}
        gridCount={gridCount}
        isGridUpdate={isGridUpdate}
        onCardDrop={handleCardDrop}
      />
    </div>
  )
}

export default Main
