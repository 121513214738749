import { createSlice } from '@reduxjs/toolkit';

export const headerSortSlice = createSlice({
    name: 'headerSort',
    initialState: {
        currentCity: {},
        currentWorks: [],
        currentTypeOfWork: [],
        searchValue: '',
        isSearchMode: false,
        searchTickets: {},
        isLoadingSearchMode: false,
        cardToDrag: null
    },
    reducers: {
        setCurrentCity: (state, action) => {
            state.currentCity = action.payload;
        },
        setCurrentWorks: (state, action) => {
            state.currentWorks = action.payload;
        },
        setCurrentTypeOfWork: (state, action) => {
            state.currentTypeOfWork = action.payload;
        },
        setSearchValue: (state, action) => {
            state.searchValue = action.payload;
        },
        setIsSearchMode: (state, action) => {
            state.isSearchMode = action.payload;
        },
        setSearchTickets: (state, action) => {
            state.searchTickets = action.payload;
        },
        setIsLoadingSearchMode: (state, action) => {
            state.isLoadingSearchMode = action.payload;
        },
        setCardToDrag: (state, action) => {
            state.cardToDrag = action.payload;
        },
    },
});

export const {
    setCurrentCity,
    setCurrentWorks,
    setSearchValue,
    setIsSearchMode,
    setSearchTickets,
    setIsLoadingSearchMode,
    setCardToDrag,
    setCurrentTypeOfWork
} = headerSortSlice.actions;

export default headerSortSlice.reducer;
