import React, { useEffect, useState } from 'react'
import { setGridCount, setIsLoadingGrid } from '../../../redux/dragContent'
import { useDispatch } from 'react-redux'
import { useSocket } from '../../../controllers/SocketProvider'
import moment from 'moment'
import { Oval } from 'react-loader-spinner'
import 'moment/locale/ru'
import Button from '../../Common/Button'
import { getCurrentTime } from '../../../help/getCurrentTime'
import MainDatesHour from '../MainDatesHour/index'
import MasterCard from '../master-card/master-card'

import './styles.scss'

const MainDates = ({ grid, isLoadingGrid, onCardDrop }) => {
  const [gridCountMasters, setGridCountMasters] = useState(1)
  const [buttonVisible, setButtonVisible] = useState(true)
  const [currentTime, setCurrentTime] = useState(() => getCurrentTime())
  moment.locale('ru')

  const dispatch = useDispatch()
  const { getGridByMaster } = useSocket()



  const handleRequestMore = () => {
    setGridCountMasters((prevCount) => prevCount + 1)
    setTimeout(() => {
      if (gridCountMasters === 3) {
        setButtonVisible(false)
      }
    }, 2000)
  }

  const getGrid = () => dispatch(setIsLoadingGrid(true))

  useEffect(() => {
    const interval = setInterval(() => {
      const cur = getCurrentTime()
      if (cur !== currentTime) {
        // console.log(cur, currentTime)
        setCurrentTime(cur)
      }
    }, 10000)
    return () => {
      console.log('clear')
      clearInterval(interval)
    }
  }, [currentTime])

  useEffect(() => {
    getGridByMaster(gridCountMasters)
    dispatch(setGridCount(2))
    getGrid()
  }, [gridCountMasters])

  // if (grid && grid.data) {
    // console.log(grid.data[0].masters[3].schedule.hours[14])
  //   console.log(grid.data[0].masters)
  // }

  return (
    <div className="dates-block">
      <div className="date-block">
        <h1 className="title-all">Все исполнители</h1>

        {grid.data &&
          grid.data.length > 0 &&
          grid.data.map((date, index) => (
            <div key={index} className="dates-block-header">
              <h2 className="dates-block-dates-wrap-item-date">
                {moment(date.date)
                  //   .subtract(1, 'days')
                  .format('dddd DD.MM.yyyy')}
              </h2>

              <div className="masters-line">
                {date.masters.length > 0 ? (
                  date.masters.map((item, index) => (
                    <div key={index} className="master-card-root">
                      <MasterCard
                        role={item.role}
                        IgnoreTimeRange={item.IgnoreTimeRange}
                        fio={item.fio}
                        lift={item.lift}
                      />

                      {/* --------------------------список часов в карточке------------------------------------------- */}
                      <div className="dates-block-dates-wrap-item-date-scroll">
                        {item.schedule?.hours?.map((hour) => (
                          <MainDatesHour
                            key={hour.id}
                            hour={hour}
                            ticket={hour.ticket}
                            currentTime={currentTime}
                            master={item}
                          />
                        ))}
                      </div>
                      {/* --------------------------список часов в карточке------------------------------------------- */}
                    </div>
                  ))
                ) : (
                  <h2 className="text-not-found-master">
                    Мастеров за выбранную дату не найдено
                  </h2>
                )}
              </div>
            </div>
          ))}

        {/* --------------------------список карточек ------------------------------------------- */}

        {!grid.data && (
          <div className="dates-block-dates-wrap-loading">
            <Oval
              strokeWidth={5}
              strokeWidthSecondary={5}
              secondaryColor={'rgba(253,99,61, 0.6)'}
              height="50"
              width="50"
              color="#fff"
            />
          </div>
        )}
      </div>

      {grid.data && grid.data.length > 0 && (
        <div className="button-more-master">
          {buttonVisible && (
            <Button
              isLoading={isLoadingGrid}
              onClick={handleRequestMore}
              text="Загрузить еще"
            />
          )}
        </div>
      )}
    </div>
  )
}

export default MainDates


//id
// :
// 20
// isContainsTakenTicket
// :
// false
// isWork
// :
// true
// ticket
// :
// [{…}]
// time
// :
// "2024-06-26T17:00:00.000Z"


//id
// :
// 553
// isFreelanceAccept
// :
// 0            был 0 стал 1
// master_id
// :
// 243
// ticket
// :
// {id: 180, category: {…}, type: 'Undefined23322', description: '110820234 ticket', price: 725.2, …}
// ticket_id
// :
// 180
// timeBegin
// :
// "2024-06-26T17:00:00.000Z"
// timeEnd
// :
// "2024-06-26T18:00:00.000Z"
//address
// :
// "г.Москва"
// amoId
// :
// 9833910
// amoURL
// :
// "https://rapplegroup.amocrm.ru/leads/detail/9833910"
// category
// :
// {id: 15, category_name: 'Дезинфекция'}
// client_name
// :
// "Ольга"
// date_create
// :
// "2023-08-11T09:55:56.000Z"
// date_delete
// :
// null
// date_update
// :
// "2023-08-11T09:56:42.000Z"
// description
// :
// "110820234 ticket"
// guarantee
// :
// true
// guaranteeData
// :
// {income: 475.2, comment: 'Лвлвлвдв', term: 5, conditions: '100'}
// id
// :
// 180
// isTiketTaken
// :
// false
// manager_id
// :
// 8
// nextTime
// :
// null
// phone
// :
// "+73839271363"
// prettier_id
// :
// "G000180"
// price
// :
// 725.2
// region
// :
// null
// road_cost
// :
// "125.00"
// status
// :
// "В работе"
// timeWhenStartedToWork
// :
// null
// transfer
// :
// 1
// type
// :
// "Undefined23322"
// work_hours
// :
// 1