import moment from "moment";
import {checkHourOpacity} from "./checkHourOpacity";

export const CheckJustHours = (hour, cur) => {

    const today = cur
    const currentDay = moment(hour).format('YYYY-MM-DD HH');

    // console.log(today, currentDay)

    if (hour) {
        if (today < currentDay) {
            return true;
        } else {
            return false;
        }
    }
}